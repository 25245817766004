import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "gatsby";
import Box from "@components/styled/Box";
import Layout from "@components/layout";
import SEO from "@components/seo";
import { rem } from "@src/theme";
import Container from "@components/styled/Container";
import Auth from "@helpers/auth";
import Columns from "@components/styled/Columns";
import Column from "@components/styled/Column";
import Products from "@components/assets/svg/products.svg";
import Articles from "@components/assets/svg/articles.svg";
import Events from "@components/assets/svg/events.svg";
import Trending from "@components/assets/svg/trending.svg";
import Projects from "@components/assets/svg/projects.svg";

const IndexPage = ({ data, ...props }) => {
  const auth = new Auth();
  const isAuthed = auth.isAuthenticated();

  const [authed, setAuthed] = useState(null);

  useEffect(() => {
    if (isAuthed) {
      setAuthed(true);
    } else {
      setAuthed(false);
    }
  }, [isAuthed]);

  return (
    <Layout uri={props.uri}>
      <SEO title="Home" />
      <Container pt={[7]}>
        <Box
          as="h1"
          fontSize={rem(28)}
          mb={4}
          p={4}
          pr={6}
          bg="blue"
          color="white"
          display="inline-block"
        >
          Home
        </Box>

        {authed ? (
          <Columns>
            <Column width={[1, 0.5]}>
              <Columns>
                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Projects
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Projects />
                    </Box>
                    <MenuActions>
                      <Link to="view-projects">View</Link>
                      <Link to="add-project">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>
                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Products
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Products />
                    </Box>
                    <MenuActions>
                      <Link to="view-products">View</Link>
                      <Link to="add-product">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>
                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Articles
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Articles />
                    </Box>
                    <MenuActions>
                      <Link to="view-articles">View</Link>
                      <Link to="add-article">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>
                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Events
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Events />
                    </Box>
                    <MenuActions>
                      <Link to="view-events">View</Link>
                      <Link to="add-event">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>
                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Web Stats
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Trending />
                    </Box>
                    <MenuActions>
                      <Link to="/view-web-stats">View</Link>
                      <Link to="/stat">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>

                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Subs & Social Stats
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Trending />
                    </Box>
                    <MenuActions>
                      <Link to="/view-subssocial-stats">View</Link>
                      <Link to="/stat">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>

                <Column width={[1, 0.5]}>
                  <MenuItem pt={4}>
                    <Box as="h4" textAlign="center" mb={4}>
                      Downloads Stats
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Trending />
                    </Box>
                    <MenuActions>
                      <Link to="/view-downloads-stats">View</Link>
                      <Link to="/stat">Add</Link>
                    </MenuActions>
                  </MenuItem>
                </Column>
              </Columns>
            </Column>
            <Column width={0.5}></Column>
          </Columns>
        ) : authed === null ? null : (
          <Box as="section" p={7} fontSize={rem(24)} textAlign="center">
            <h2>Nothing to see here, login to use this tool.</h2>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

const MenuItem = styled(Box)`
  background-color: #f9f9f9;
  margin-bottom: ${p => p.theme.space[4]};
  border: 1px solid #eee;

  svg {
    width: ${rem(40)};
    fill: ${p => p.theme.colors.blue};
  }
`;

const MenuActions = styled.div`
  display: flex;
  border-top: 1px solid #eee;
  margin-top: ${rem(15)};
  a {
    color: ${p => p.theme.colors.black};
    text-decoration: none;
    font-size: ${rem(14)};
    display: inline-block;
    width: 50%;
    text-align: center;
    padding: ${rem(10)};
    &:hover {
      font-weight: 600;
    }
  }
`;
export default connect(
  state => ({}),
  null
)(IndexPage);
